import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';  // Using Grid for layout

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

async function fetchUserName(userId) {
    let { data, error } = await supabase
        .from('Users')
        .select('name, surname')
        .eq('id', userId)
        .single();

    if (error) {
        console.error(error);
        return null;
    }

    return `${data.name} ${data.surname}`;
}

function My_memorials() {
    const [memorials, setMemorials] = useState([]);
    const userId = Cookies.get('userId');
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        fetchUserName(userId).then(setUserName);
    }, [userId]);

    useEffect(() => {
        const fetchMyMemorials = async () => {
            setIsLoading(true);
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('*')
                    .eq('owner_id', userId);

                if (error) {
                    throw error;
                }

                if (data) {
                    setMemorials(data.map((item, i) => ({
                        ...item,
                        id: i + 1,
                        created_on: new Date(item.created_at).toLocaleDateString(),
                        published: item.published ? 'Yes' : 'No',
                    })));
                }
            } catch (error) {
                console.error('Error fetching my memorials:', error.message);
            }
            setIsLoading(false);
        };

        if (userId) {
            fetchMyMemorials();
        }
    }, [userId]);

    const updatePublishedStatus = async (memorialId, newStatus) => {
        try {
            const { error } = await supabase
                .from('memorials')
                .update({ published: newStatus })
                .eq('memorial_Id', memorialId);

            if (error) {
                console.error('Error updating published status:', error.message);
                return false;
            }
            return true;
        } catch (err) {
            console.error('Unexpected error:', err);
            return false;
        }
    };

    const handleTogglePublished = async (memorialId, currentStatus) => {
        const newStatus = currentStatus === 'Yes' ? false : true;
        const success = await updatePublishedStatus(memorialId, newStatus);

        if (success) {
            setMemorials((prev) =>
                prev.map((memorial) =>
                    memorial.memorial_Id === memorialId
                        ? { ...memorial, published: newStatus ? 'Yes' : 'No' }
                        : memorial
                )
            );
        } else {
            console.error('Failed to update published status.');
        }
    };

    const handleEditMemorial = (memorialId) => {
        Cookies.set('memorialId', memorialId);
        navigate('/Edit_memorial2');
    };

    const handleViewMemorial = (memorialId) => {
        Cookies.set('memorialId', memorialId);
        navigate('/Memorial');
    };

    const handleDeleteMemorial = async (memorialId) => {
        try {
            const { error } = await supabase
                .from('memorials')
                .delete()
                .eq('memorial_Id', memorialId);

            if (error) {
                console.error('Error deleting memorial:', error.message);
                return;
            }

            setMemorials((prev) => prev.filter((memorial) => memorial.memorial_Id !== memorialId));
            console.log('Memorial deleted successfully');
        } catch (err) {
            console.error('Unexpected error:', err);
        }
    };

    const renderCards = () => {
        return memorials.map((memorial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">
                            {memorial.deceased_name} {memorial.deceased_surname}
                        </Typography>
                        <Typography variant="body2">Created On: {memorial.created_on}</Typography>
                        <Typography variant="body2">
                            Date of Birth: {memorial.deceased_date_of_birth}
                        </Typography>
                        <Typography variant="body2">
                            Date of Death: {memorial.deceased_date_of_death}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mt: 2,
                                justifyContent: isMobile ? 'center' : 'flex-start',
                            }}
                        >
                            <Typography variant="body2">Published:</Typography>
                            <Switch
                                checked={memorial.published === 'Yes'}
                                onChange={() =>
                                    handleTogglePublished(memorial.memorial_Id, memorial.published)
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                backgroundColor: '#1976d2',
                                color: '#fff',
                                '&:hover': { backgroundColor: '#115293' },
                            }}
                            onClick={() => handleEditMemorial(memorial.memorial_Id)}
                        >
                            Edit
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                backgroundColor: '#6a0dad',
                                color: '#fff',
                                '&:hover': { backgroundColor: '#4b0082' },
                            }}
                            onClick={() => handleViewMemorial(memorial.memorial_Id)}
                        >
                            View
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                backgroundColor: '#d32f2f',
                                color: '#fff',
                                '&:hover': { backgroundColor: '#9a0007' },
                            }}
                            onClick={() => handleDeleteMemorial(memorial.memorial_Id)}
                        >
                            Delete
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        ));
    };

    return (
        <div>
            <Navbar />
            <div
                style={{
                    backgroundImage: `url('/images/background1.jpg')`,
                    backgroundSize: 'cover',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: '85%',
                        margin: '50px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '10px',
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h4"
                        gutterBottom
                        style={{ marginBottom: '20px' }}
                    >
                        {userName}'s Memorials
                    </Typography>
                    <Grid container spacing={2}>
                        {renderCards()}
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default My_memorials;
